<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="60%"
    :show-close="false"
    :close-on-click-modal="false"
    @close="handleClose">
    <el-card v-loading="loading">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-col :span="12">
          <el-form-item label="资讯类型:" prop="informationType">
            <el-select v-model="form.informationType" @change="changeInfomationType" placeholder="请选择资讯类型">
              <el-option v-for="(item, index) in infoTypeList" :key="index" :label="item.dictionaryName" :value="item.dictionaryCode"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="资讯名称:" prop="informationId">
            <el-select v-model="form.informationId" placeholder="请选择资讯名称">
              <el-option v-for="(item, index) in infoList" :key="index" :label="item.informationTitle" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否热门资讯:">
            <el-select v-model="form.isHot" placeholder="请选择是否为热门资讯">
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否首页展示:">
            <el-select v-model="form.isHome" placeholder="请选择是否在首页展示">
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="上下架日期:">
            <el-date-picker
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="form.value1"
              @input="changeDate123"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureCommit('form')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequest, postRequestAnthor } from '@/api'
export default {
  data () {
    return {
      dialogVisible: true,
      loading: false,
      form: {
        informationType: '',
        informationId: '',
        isHot: '',
        isHome: '',
        beginDate: '',
        endDate: '',
        releaseCompany: '',
        createBy: '2'
      },
      rules: {
        informationType: [
          {required: true, message: '请选择资讯类型', trigger: 'change'}
        ],
        informationId: [
          {required: true, message: '请选择资讯名称', trigger: 'change'}
        ]
      },
      time1: '',
      time2: '',
      infoTypeList: [],
      infoList: [],
      expireTimeOption: {
        disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      }
    }
  },
  props: ['info'],
  created(){
    postRequestAnthor('/information/queryinformationbytype', {informationType:this.info.informationType}).then(res=>{
      console.log(res, '根据资讯类型查询资讯')
      this.infoList = res
    })
  },
  mounted(){
    if (this.info != '') {
      this.loading = true
      postRequestAnthor('/informationrelease/queryreleaseinfo', {id: this.info.id}).then(res=>{
        this.loading = false
        console.log(res, '资讯文案发布单条数据')
        if (res.beginDate !=null && res.endDate !=null) {
          res.value1 = [res.beginDate, res.endDate]
        }
        res.informationId = Number(res.informationId)
        this.form = res
      }).catch(()=>{
        this.loading = false
      })
    }
    postRequestAnthor('/dict/getDictByCategory', {dictionaryCategoryCode: '08'}).then(res=>{
      console.log(res, '资讯类型')
      this.infoTypeList = res
    })
    postRequestAnthor('/information/queryinformationbytype', {informationType:this.info.informationType}).then(res=>{
      console.log(res, '根据资讯类型查询资讯')
      this.infoList = res
    })
    postRequest('/company/queryCompany', {}).then(res=>{
      console.log(res, 'jigou')
    })
  },
  methods: {
    handleClose(){
      this.$emit('closeCopyRelease')
    },
    changeDate123(val){
      console.log(123)
      this.$forceUpdate()
      console.log(val)
      this.form.beginDate = val[0]
      this.form.endDate = val[1]
    },
    // 根据类型查询资讯列表
    changeInfomationType(val){
      this.form.informationId = ''
      postRequestAnthor('/information/queryinformationbytype',{informationType: val}).then(res=>{
        console.log(res, '根据类型查询资讯列表')
        this.infoList = res
      })
    },
    changetime1 (time1) {
      if (!time1) {
        this.$message('请选择开始日期')
      } else {
        let timer = time1.split('-')
        this.time1 = timer.join('')
        console.log(this.time1, 'time1')
      }
    },
    changetime2 (time2) {
      if (!time2) {
        this.$message('请选择结束日期')
      } else {
        let timer = time2.split('-')
        this.time2 = timer.join('')
        console.log(this.time2, 'time2')
        if (Number(this.time2) < Number(this.time1)) {
          this.form.date2 = ''
          this.$message({
            type: 'danger',
            message: '结束日期必须在开始日期之后'
          })
        }
      }
    },
    sureCommit(form){
      this.$refs[form].validate((valid) => {
          if (valid) {
            if (this.info != '') {
              postRequestAnthor('/informationrelease/update', this.form).then(res=>{
                console.log(res, '修改接口')
                this.$message({
                  message: '修改成功',
                  type: 'success'
                })
                this.handleClose()
              })
            } else {
              postRequestAnthor('/informationrelease/addsave', this.form).then(res=>{
                console.log(res, '资讯发布结果')
                this.$message({
                    message: '添加成功',
                    type: 'success'
                  })
                  this.handleClose()
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100% !important;
}
.el-range-editor {
  width: 100%;
}
.el-card {
  margin-top: -20px;
}
.dialog-footer {
  margin-top: -20px !important;
}
</style>